import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'link']

  get className () {
    return this.element.dataset.classname
  }

  initialize () {
    if (this.hasSelectTarget) {
      if (!('URLSearchParams' in window)) return

      const urlParams = new URLSearchParams(location.search)
      const currentSortKey = urlParams.get('sort_order')

      Array.from(this.selectTarget.options).forEach(option => {
        if (option.value === currentSortKey) {
          option.selected = true
        } else if (!currentSortKey && option.value === 'recommend_reviews') {
          option.selected = true
        }
      })
    }
  }

  setUrl (e, sortId) {
    const currentUrl = new URL(window.location.href)
    const currentParams = new URLSearchParams(currentUrl.search)

    if (sortId) {
      currentParams.set('sort_order', sortId)
    } else {
      currentParams.delete('sort_order')
    }

    currentUrl.search = currentParams.toString()
    const targetUrl = currentUrl.toString()

    return targetUrl
  }

  chengeSelect (e) {
    const sortId = e.target.value
    location.href = this.setUrl(e, sortId)
  }
}
