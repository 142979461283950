import { Controller } from '@hotwired/stimulus'

const VIEW_LIMIT = 5

export default class extends Controller {
  static targets = ['row', 'open', 'close']

  initialize () {
    console.log(this.rowTargets.length)
    if (this.rowTargets.length > VIEW_LIMIT) {
      this.openTarget.style.display = 'flex'
    }

    this.rowTargets.forEach((row, index) => {
      if (index >= VIEW_LIMIT) {
        row.classList.add(`${this.className()}::hidden`)
      }
    })
  }

  className () {
    return this.element.dataset.classname
  }

  open () {
    this.rowTargets.map((facility, index) => {
      if (index >= VIEW_LIMIT) {
        facility.classList.remove(`${this.className()}::hidden`)
      }
    })
    this.openTarget.style.display = 'none'
    this.closeTarget.style.display = 'flex'
  }

  close () {
    this.rowTargets.map((facility, index) => {
      if (index >= VIEW_LIMIT) {
        facility.classList.add(`${this.className()}::hidden`)
      }
    })
    this.openTarget.style.display = 'flex'
    this.closeTarget.style.display = 'none'
  }
}
