import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['description']

  open () {
    this.descriptionTarget.style.display = 'block'
  }

  close () {
    this.descriptionTarget.style.display = 'none'
  }
}
